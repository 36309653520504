import * as React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import AboutHeader from '../components/about_header'
import Button from '../components/button'
import { StaticImage } from 'gatsby-plugin-image'
import Container from '../components/container'
import Divider from '../components/divider'
import Link from '../components/link'
import VideoModal from '../components/video_modal'

const CareersPage = () => (
  <Layout>
    <Seo
      title='We’re Hiring'
      description='At Roadster, the most talented engineers, designers and industry leaders are building the ultimate commerce solutions to make the car buying and selling process better for everyone. Join us.'
    />
    <AboutHeader
      title='Join Us'
      subtitle='View current opportunities at Roadster, a CDK Global brand.'>
      <Button
        variant='outline'
        href='https://careers.cdkglobal.com/job-search-results/?keyword=Roadster'
        minWidth={true}
        children='See Open Roles'
        className='mt-3'
      />
    </AboutHeader>
    <div className='Section'>
      <Container>
        <p className='text-xl sm:text-2xl font-light text-center leading-tight max-w-measure-2 mx-auto mb-0'>
          At Roadster, the most talented engineers, designers and industry leaders are building the ultimate commerce solutions with one goal in mind — make the car buying and selling process better for everyone.
        </p>
      </Container>
    </div>
    <div className='py-4 relative bg-dark-gray flex flex-col justify-center' style={{ minHeight: '600px' }}>
      <StaticImage
        placeholder='blurred'
        src='../images/gptw-photo.jpg'
        alt=''
        className='absolute inset-0'
      /> 
      <Container className='Container text-white relative' style={{ maxWidth: '74rem' }}>
        <div className='sm:flex mb-3 sm:mb-0'>
          <div className='sm:shrink-0'>
            <StaticImage
              width={140}
              alt='Great Place To Work Certified November 2020 - November 2021 USA'
              src='../images/gptw-badge.png'
            />
          </div>
          <div className='sm:flex-1 text-md leading-relaxed sm:pl-4 md:pl-5'>
            <h2 className='text-xl sm:text-2xl font-light mb-em'>
              Employees Rave About Roadster
            </h2>
            <p>
              Roadster employees say we are a “Great Place to Work.” They appreciate our welcoming company culture and the autonomy they’re given to manage their responsibilities. They take pride in providing our customers with “excellent” service and are empowered to take take time off when they need it.
            </p>
            <p>
              <Link
                className='font-semi-bold uppercase'
                href='https://www.greatplacetowork.com/certified-company/7022082'
                rel='noopener noreferrer'
                target='_blank'
                children='Learn More'
                arrowDirection='right'
              />
            </p>
          </div>
        </div>
      </Container>
    </div>
    <div className='Section'>
      <Container size='lg'>
        <VideoModal videoId='309529064'>
          <button
            type='button'
            className='block relative'>
            <StaticImage
              src='../images/driven-poster.jpg'
              alt=''
              placeholder='blurred'
              quality={100}
            />
            <StaticImage
              alt='Play Video'
              src='../images/icon-play.png'
              className='absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 hover:opacity-60 transition-opacity duration-300'
              placeholder='tracedSVG'
              style={{ width: '10%' }}
            />
          </button> 
        </VideoModal>
        <div className='text-center mt-4 sm:mt-5'>
          <h2 className='text-xl sm:text-2xl font-light text-center mt-0'>
            One Team, Driving Change
          </h2>
          <p className='text-md text-center max-w-measure-4 mx-auto mb-0 leading-relaxed'>
            We share a deep desire to do something purposeful with our time and talent. We’re united in our values: respect, hard work, family, customer centricity and more. And we like to have fun in the process. No egos. No pretentiousness. Just one team, driving change. We couldn’t be any tighter than if we all tried to cram inside a 1963 VW Beetle. If Roadster sounds like the right fit for you, join us. We're DRIVEN.
          </p>
        </div>
      </Container>
    </div>
    <Container size='lg'>
      <Divider />
    </Container>
    <div className='Section text-md leading-relaxed'>
      <Container>
        <div className='text-center'>
          <h2 className='text-xl sm:text-2xl font-light text-center mt-0'>
            Love What You Do
          </h2>
          <p className='mb-4 sm:mb-5'>
            If you ask Roadster employees why they love working here, you’ll get lots of different answers. But most boil down to three main reasons:
          </p>
        </div>
        <div className='grid gap-4 sm:grid-cols-2 lg:grid-cols-3'>
          <div>
            <div className='CircleNum'>
              1
            </div>
            <h3 className='mt-3 mb-2'>
              The People
            </h3>
            <p>
              At Roadster, our people create our culture. While we come from diverse backgrounds and have taken different paths to get here, we’re united in our mission and values. We care deeply about our customers, coworkers and communities. It’s evident whether we’re bringing a product to market, tossing back drinks during a virtual happy hour or serving meals to the homeless.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              2
            </div>
            <h3 className='mt-3 mb-2'>
              The Product
            </h3>
            <p>
              We invest more in our product than in any other area of the business, and it shows. We’ve developed the auto industry’s leading digital sales platform, and we’re not content to rest on our laurels. We’re constantly listening to and learning from our customers to ensure we’re improving our product and developing new ones to meet their evolving needs.
            </p>
          </div>
          <div>
            <div className='CircleNum'>
              3
            </div>
            <h3 className='mt-3 mb-2'>
              The Opportunity
            </h3>
            <p>
              It’s an incredible opportunity to join a team on the cusp of something really big and to play an integral role in its creation. At Roadster, everyone’s empowered to do their own job, and each individual has a direct impact on the company’s success. Beyond building the business, you’ll also have the opportunity to advance your own career. You’ll cultivate a network of allies who will help you grow both professionally and personally.
            </p>
          </div>
        </div>
      </Container>
    </div>
    <StaticImage
      alt='Team Photo'
      placeholder='blurred'
      src='../images/team.jpg'
      quality={100}
    />
  </Layout>
)

export default CareersPage
